<template>
  <div>

<!--    <ckeditor-->
<!--        :editor="editor"-->
<!--        v-model="editorData"-->
<!--        :config="editorConfig"-->

<!--        @ready="onEditorReady"-->
<!--    />-->

<!--    <code-->
<!--        class="block whitespace-pre overflow-x-scroll mt-5"-->
<!--        v-text="editorData"-->
<!--    ></code>-->

    <input-textarea
      :text.sync="text"
    />

    <code>{{text}}</code>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
// import CKEditor                 from '@ckeditor/ckeditor5-vue2'
// import Editor                   from 'ckeditor5-custom-build/build/ckeditor'
import InputTextarea            from '../../components/input/InputTextarea.vue'

export default {
  components: {
    InputTextarea,
    // ckeditor: CKEditor.component
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    // const editorData = ref('<p>Formulaire :</p><p>Voici le champ 1 : <span class="restricted-editing-exception">Value du champs 1</span></p><p>Voici le champ 2 : <span class="restricted-editing-exception">Value du champs 2</span></p><p>Voici le champ 3 : <span class="restricted-editing-exception">Value du champs 3</span></p>')
    // const editorConfig = ref({
    //   removePlugins: ['Title'],
    //   // toolbar: { items: ['restrictedEditing'] },
    //   toolbar: {
    //     items: [
    //       'accessibilityHelp',
    //       'undo',
    //       'redo',
    //       '|',
    //       'heading',
    //       // 'style',
    //       '|',
    //       'bold',
    //       'italic',
    //       'underline',
    //       'strikethrough',
    //       'subscript',
    //       'superscript',
    //       'link',
    //       'bulletedList',
    //       'numberedList',
    //       'todoList',
    //       '|',
    //       'alignment',
    //       'outdent',
    //       'indent',
    //       '|',
    //       'imageInsert',
    //       // 'imageUpload',
    //       'blockQuote',
    //       'insertTable',
    //       'mediaEmbed',
    //       // 'code',
    //       // 'codeBlock',
    //       'findAndReplace',
    //       '|',
    //       'fontColor',
    //       'fontBackgroundColor',
    //       'fontFamily',
    //       'fontSize',
    //       'highlight',
    //       '|',
    //       // 'htmlEmbed',
    //       // 'pageBreak',
    //       // 'removeFormat',
    //       // 'selectAll',
    //       'horizontalLine',
    //       'showBlocks',
    //       '|',
    //       'specialCharacters',
    //       '|',
    //       'restrictedEditingException',
    //       'sourceEditing',
    //       // 'textPartLanguage',
    //     ]
    //   },
    //   language: 'fr',
    //   image: {
    //     toolbar: [
    //       'imageTextAlternative',
    //       'toggleImageCaption',
    //       'imageStyle:inline',
    //       'imageStyle:block',
    //       'imageStyle:side',
    //       'linkImage'
    //     ]
    //   },
    //   table: {
    //     contentToolbar: [
    //       'tableColumn',
    //       'tableRow',
    //       'mergeTableCells',
    //       'tableCellProperties',
    //       'tableProperties'
    //     ]
    //   }
    // })
    // const editor = ref(Editor.Editor)
    // const editor = Editor.Editor

    const text = ref("")

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(editorData, val => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // const onEditorReady = editor => {
    //   console.log('ok')
    //   console.log(editor)
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
// console.log(Editor.Editor)
    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      // editor,
      // editorData,
      // editorConfig,

      // Data
      text,

      // Computed

      // Methods
      // onEditorReady,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>